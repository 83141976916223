import React from "react"
import { Link, graphql } from 'gatsby'
import styles from '../styles/tag.module.css'

export default ({ pageContext, data}) => {
    const { tag } = pageContext
    return (
          <div className={styles.tagContainer}>
            <div className="h1">
              Posts Involving <span className="font-italic">{tag}</span>
            </div>
            <table>
                <tbody>
                    <tr className="lead">
                        <th>
                            Title
                        </th>
                        <td>
                            Date
                        </td>
                    </tr>
                    {data.allMarkdownRemark.edges.map(({node}) => (
                        <tr className="lead">
                            <th>
                                <Link
                                    to={node.frontmatter.path}>
                                        {node.frontmatter.title}
                                </Link>
                            </th>
                            <td>
                                <span>
                                    {node.frontmatter.date}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
          </div>
    )
  }

  export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            tags
          }
          excerpt
          timeToRead
          frontmatter {
            title
            date
            path
          }
        }
      }
    }
  }
`